/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  ApolloLink,
  NormalizedCacheObject,
  ApolloCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useClientEnvVarsStore } from '@marriott/mi-store-utils';
import { inspect } from 'util';
import { v4 as uuidv4 } from 'uuid';

import {
  useGetOperationSignature,
  useGetUxlEndpoint,
  isServer,
  isLocalClientSide,
  normalizeDeployedEnvType,
  doDebugLog,
  validateHeaders,
  isEmptyObject,
} from './hooks';
import { OperationSignature, DeployedEnvType, UXLHttpHeaders, NonEmptyString, ApolloEnvVars } from '../apollo/types';

//VoidCache will replace InMemorycache methods with dummy data to avoid reading and writing operations on apollo client cache, Which will reduce TBT and improve performance.
const emptyCacheObj = {};
export class VoidCache extends ApolloCache<NormalizedCacheObject> {
  read() {
    return null;
  }
  write() {
    return undefined;
  }
  diff() {
    return {};
  }
  watch() {
    return () => {
      return null;
    };
  } // eslint-disable-line
  evict() {
    return false;
  }
  restore() {
    return this;
  }
  reset: () => Promise<void> = async () => {
    console.log('reset');
  };
  extract() {
    return emptyCacheObj;
  }
  removeOptimistic() {
    return null;
  }
  performTransaction() {
    return;
  }
}

const miErrorLink = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return onError(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }: any) =>
        doDebugLog(`Message: ${message}, Location: ${locations}, Path: ${path}`, 'error')
      );

    if (networkError) doDebugLog(`[Network error]: ${networkError}`, 'error');
  });
};

export const miResHeaderLink = () => {
  return new ApolloLink((operation, forward) => {
    const headers = operation.getContext()['headers'] || {};

    // Check session storage for uxlMigrationStatus
    const migrationStatus = sessionStorage.getItem('uxlMigrationStatus');
    if (migrationStatus && !headers['Uxl-Migration-Status']) {
      operation.setContext({
        headers: {
          ...headers,
          'Uxl-Migration-Status': migrationStatus,
        },
      });
      return forward(operation);
    } else {
      return forward(operation).map(data => {
        const { response: httpResponse } = operation.getContext();
        // Check for the 'uxl-migration-status' header and store its value
        for (const [key, value] of httpResponse.headers.entries()) {
          if (key.toLowerCase() === 'uxl-migration-status') {
            sessionStorage.setItem('uxlMigrationStatus', value);
            doDebugLog('uxl-migration-status header received: ', value);
          }
        }
        return data;
      });
    }
  });
};

const miConsoleLink = () => {
  return new ApolloLink((operation, forward) => {
    doDebugLog(`Operation Name ${operation.operationName}, ${JSON.stringify(operation)}`, 'info');
    return forward(operation).map(data => {
      doDebugLog(`Response Data ${inspect(inspect(operation.getContext()))}`, 'info');
      return data;
    });
  });
};

// Returns headers to the context so httpLink can read them.
// Reads operation signature in from an imported json file.
export const getAuthLink = (operationSignatures: OperationSignature[], envVars: ApolloEnvVars) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isLowerEnv = envVars?.DEPLOYED_ENV_TYPE === 'lower';
  const clientEnvVars = useClientEnvVarsStore.getState().envVarsObject;

  return setContext((req, { headers }) => {
    // Construct request headers by combining lib config and query headers.
    const buildHeaders: UXLHttpHeaders = {
      // Headers not unique to operation can be set when ApolloClient is initialized.
      'accept-language': (envVars?.APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG ||
        process.env['APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG'] ||
        clientEnvVars['APOLLOGRAPHQL_PUBLIC_ACCEPT_LANG']) as NonEmptyString,
      'apollographql-client-name': (envVars?.APOLLOGRAPHQL_PUBLIC_CLIENT_NAME ||
        process.env['APOLLOGRAPHQL_PUBLIC_CLIENT_NAME'] ||
        clientEnvVars['APOLLOGRAPHQL_PUBLIC_CLIENT_NAME']) as NonEmptyString,
      'apollographql-client-version': (envVars?.APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION ||
        process.env['APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION'] ||
        clientEnvVars['APOLLOGRAPHQL_PUBLIC_CLIENT_VERSION']) as NonEmptyString,
      // If a request ID is passed on the query context headers node, use that.
      // Otherwise generate one.
      'x-request-id':
        headers['x-request-id'] ??
        `${
          envVars?.APOLLOGRAPHQL_PUBLIC_CLIENT_NAME ||
          process.env['APOLLOGRAPHQL_PUBLIC_CLIENT_NAME'] ||
          clientEnvVars['APOLLOGRAPHQL_PUBLIC_CLIENT_NAME']
        }-${uuidv4()}`,
      // Validate headers object by removing any values with empty strings.
      // Headers passed on the query take precedence over default values.
      ...(!isEmptyObject(headers) && validateHeaders(headers)),
    };
    doDebugLog(`buildHeaders: ${JSON.stringify(buildHeaders)}`, 'info');

    // If application name provided in process.env, add to headers.
    const hasApplicationName =
      (envVars?.APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME &&
        envVars?.APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME.trim().length > 0) ||
      (process.env['APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME'] &&
        process.env['APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME'].trim().length > 0) ||
      clientEnvVars['APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME'].trim().length > 0;
    if (hasApplicationName) {
      buildHeaders['application-name'] = (envVars?.APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME ||
        process.env['APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME'] ||
        clientEnvVars['APOLLOGRAPHQL_PUBLIC_APPLICATION_NAME']) as NonEmptyString;
    }

    // Disabled by default for dev and testing "lower environments".
    // Can also be enabled via environment variable.
    const requireSafelisting =
      !isLowerEnv ||
      envVars?.APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING === 'true' ||
      process.env['APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING'] === 'true' ||
      clientEnvVars['APOLLOGRAPHQL_PUBLIC_REQUIRE_SAFELISTING'] === 'true';
    if (requireSafelisting) {
      buildHeaders['graphql-require-safelisting'] = 'true' as NonEmptyString;
      buildHeaders['graphql-operation-signature'] = useGetOperationSignature(
        `${req.operationName}`,
        operationSignatures
      ) as NonEmptyString;
    }
    // Different endpoints and auth are used during local dev
    // because of cors issues with NGINX endpoints.
    if (isLocalClientSide || isServer) {
      buildHeaders['Authorization'] = ((buildHeaders['Authorization'] ? buildHeaders['Authorization'] + ',' : '') +
        `Basic ${
          envVars?.APOLLOGRAPHQL_AUTH_TOKEN ||
          process.env['APOLLOGRAPHQL_AUTH_TOKEN'] ||
          clientEnvVars['APOLLOGRAPHQL_AUTH_TOKEN']
        }`) as NonEmptyString;
    }
    return {
      headers: buildHeaders,
    };
  });
};

export const getAdditiveLink = (operationSignatures: OperationSignature[], envVars: ApolloEnvVars) => {
  return from([
    getAuthLink(operationSignatures, envVars),
    miErrorLink(),
    miConsoleLink(),
    miResHeaderLink(),
    createHttpLink({
      uri: ({ operationName }): any => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useGetUxlEndpoint(operationName, envVars);
      },
    }),
  ]);
};

export const MiApolloClient = (operationSignatures: OperationSignature[], deployedEnvType: string) => {
  const normalizedEnvType = normalizeDeployedEnvType(deployedEnvType);
  return new ApolloClient({
    ssrMode: isServer,
    // TODO: Populate client-side cache with initial state passed from SSR Apollo instance if provided.
    // cache: windowApolloState ? new InMemoryCache().restore(windowApolloState) : new InMemoryCache(),
    cache: new InMemoryCache(),
    link: getAdditiveLink(operationSignatures, { DEPLOYED_ENV_TYPE: normalizedEnvType }),
  });
};

/**
 * Returns ApolloClient instance
 * @param operationSignatures Your array of operation signature objects, provided by UXL team.
 * @param deployedEnvType "higher" or "lower" based on deployed environment type.
 * @param inMemoryCacheConfig Any additional configuration for inMemoryCache: https://www.apollographql.com/docs/react/caching/cache-configuration/
 * @param initialState Initial state from SSR render, if provided.
 * @param envVars ApolloEnvVars object containing the client config values
 * @returns
 */
export const NextMiApolloClient = (
  operationSignatures: OperationSignature[],
  deployedEnvType: string, // TODO: require an ApolloEnvVars object, remove this
  inMemoryCacheConfig = {},
  initialState = {},
  envVars?: ApolloEnvVars
) => {
  const normalizedEnvType: DeployedEnvType = normalizeDeployedEnvType(envVars?.DEPLOYED_ENV_TYPE || deployedEnvType);

  doDebugLog(
    `NextMiApolloClient: deployedEnvType: ${deployedEnvType}, normalizeDeployedEnvType: ${normalizedEnvType}, initialState: ${JSON.stringify(
      initialState
    )}, envVars: ${JSON.stringify(envVars)}`,
    'log'
  );

  return new ApolloClient({
    ssrMode: isServer,
    cache: new InMemoryCache({ ...inMemoryCacheConfig }).restore(initialState),
    link: getAdditiveLink(operationSignatures, { DEPLOYED_ENV_TYPE: normalizedEnvType, ...envVars }), // TODO: just pass envVars
  });
};

/**
 * This method can be used by apps, where apollo caching is not required.
 * This function will disable the in-memory caching feature of the Apollo client.
 * Returns ApolloClient instance
 * @param operationSignatures Your array of operation signature objects, provided by UXL team.
 * @param deployedEnvType "higher" or "lower" based on deployed environment type.
 * @param initialState Initial state from SSR render, if provided.
 * @returns
 */
export const NextMiApolloClientWithoutCache = (
  operationSignatures: OperationSignature[],
  deployedEnvType: string, // TODO: require an ApolloEnvVars object, remove this
  initialState = {}
) => {
  const normalizedEnvType: DeployedEnvType = normalizeDeployedEnvType(deployedEnvType);
  doDebugLog(
    `NextMiApolloClient: deployedEnvType: ${deployedEnvType}, normalizeDeployedEnvType: ${normalizedEnvType}, initialState: ${JSON.stringify(
      initialState
    )}`,
    'log'
  );

  return new ApolloClient({
    ssrMode: isServer,
    cache: new VoidCache(), //new InMemoryCache({ ...inMemoryCacheConfig }).restore(initialState),
    link: getAdditiveLink(operationSignatures, { DEPLOYED_ENV_TYPE: normalizedEnvType }),
  });
};

// For use in non-SSR react contexts.
export const useMiApolloClient = MiApolloClient;
// Non-hook, for use SSR side in utils like getServerSideProps.
export const getMiApolloClient = MiApolloClient;

// For use with Next, React hook version.
export const useNextMiApolloClient = NextMiApolloClient;
// Non-hook, for use SSR side in utils like getServerSideProps.
export const getNextMiApolloClient = NextMiApolloClient;

// For use with Next, React hook version, without cache.
export const useNextMiApolloClientWithoutCache = NextMiApolloClientWithoutCache;
